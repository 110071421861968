<div class="example-container mat-elevation-z8">
  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div class="example-table-container">
    <table matSort (matSortChange)="sortData($event)" mat-table [dataSource]="data" class="example-table gridTable">
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">{{ row['StatusName'] || '---'}} </td>
      </ng-container>

      <ng-container matColumnDef="bamba_id">
        <th mat-header-cell *matHeaderCellDef>Bamba ID</th>
        <td mat-cell *matCellDef="let row">{{ row['BambaReservationID'] || '---'}}</td>
      </ng-container>

      <ng-container matColumnDef="res">
        <th mat-header-cell *matHeaderCellDef>Res. #</th>
        <td mat-cell *matCellDef="let row">{{ row['AgentReferenceNumber'] || '---'}}</td>
      </ng-container>

      <ng-container matColumnDef="tour_name">
        <th mat-header-cell *matHeaderCellDef>Tour Name</th>
        <td mat-cell *matCellDef="let row">{{ row['TourName'] || '---' }}</td>
      </ng-container>

      <ng-container matColumnDef="pax">
        <th mat-header-cell *matHeaderCellDef>Pax</th>
        <td mat-cell
            *matCellDef="let row">{{ row['NumberOfPassengers'] }}</td>
      </ng-container>

      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef>Customer</th>
        <td mat-cell *matCellDef="let row">{{ getCustomerName(row) }}</td>
      </ng-container>

      <ng-container matColumnDef="purchase">
        <th mat-header-cell *matHeaderCellDef>Purchase</th>
        <td mat-cell *matCellDef="let row">{{ row['PurchaseDateFormat'] || '---'}}</td>
      </ng-container>

      <ng-container matColumnDef="departure">
        <th mat-header-cell *matHeaderCellDef>Departure</th>
        <td mat-cell *matCellDef="let row">{{ row['DepartureDateFormat'] || '---'}}</td>
      </ng-container>

      <ng-container matColumnDef="lead_traveller">
        <th mat-header-cell *matHeaderCellDef>Lead Traveller</th>
        <td mat-cell *matCellDef="let row">{{ row['LeadTraveller'] ? row['LeadTraveller']['FullName'] : '' }}</td>
      </ng-container>

      <ng-container matColumnDef="gross_price">
        <th mat-header-cell *matHeaderCellDef>Gross Price</th>
        <td mat-cell *matCellDef="let row">{{ row['GrossPriceFormated'] || '---'}}</td>
      </ng-container>




      <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let element" class="btnMore">
          <div fxFlex="row" fxLayoutAlign="end center">
            <button mat-icon-button aria-label="Cancel"
                    (click)="$event.stopPropagation();cancelReservation(element.id)">
              <mat-icon class="secondary-text ">cancel</mat-icon>
            </button>

            <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                    (click)="$event.stopPropagation();">
              <mat-icon class="secondary-text">more_vert</mat-icon>
            </button>

            <mat-menu #moreMenu="matMenu">
              <!--              <button *ngIf="element | showDocument:'forma'" (click)="$event.stopPropagation();showInvoice(element)"-->
              <!--                      mat-menu-item aria-label="invoice">-->
              <!--                <span>Invoice</span>-->
              <!--              </button>-->
              <!--              <button *ngIf="element | showDocument:'itinerary'"-->
              <!--                      (click)="$event.stopPropagation(); showBookingInformation(element)" mat-menu-item-->
              <!--                      aria-label="booking">-->
              <!--                <span>Booking confirmation</span>-->
              <!--              </button>-->
              <!--              <button *ngIf="element | showDocument:'offer'"-->
              <!--                      (click)="$event.stopPropagation(); showOffer(element)" mat-menu-item-->
              <!--                      aria-label="booking">-->
              <!--                <span>Offer</span>-->
              <!--              </button>-->


            </mat-menu>
          </div>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="columnsData"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsData;"
          (click)="$event.stopPropagation();detailBooking(row._id)">
      </tr>
    </table>
  </div>
</div>
